import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

import { MenuPermissionsService } from '../../services/menu-permissions.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  @Output() menuItemClicked = new EventEmitter<any>();

  @Output() submenuSelected = new EventEmitter<any[]>();

  selectedMenu: any = null;

  selectedSubmenu: any;

  model: any[] = [
    {
      items: [
        {
          label: 'SLM',
          icon: 'domain',
          id: 'SEL_SLM',
          items: [
            {
              label: 'MODULES.SLM.METRICS',
              id: 'SEL_SLM_METRICS',
              routerLink: ['/slm/metrics'],
              requiredPermission: 'Metric View',
            },
            {
              label: 'MODULES.SLM.CONTRACTS',
              id: 'SEL_SLM_CONTRACTS',
              routerLink: ['/slm/contracts'],
              requiredPermission: 'Contract View',
            },
            {
              label: 'MODULES.SLM.CATALOG',
              id: 'SEL_SLM_CATALOG',
              routerLink: ['/slm/catalog'],
              requiredPermission: 'Metric catalog View',
            },
            {
              label: 'MODULES.SLM.ORGANIZATIONS',
              id: 'SEL_SLM_ORGANIZATIONS',
              routerLink: ['/slm/organizations'],
              requiredPermission: 'Organizations View',
            },
            {
              label: 'MODULES.SLM.METRIC_CONNECTOR',
              id: 'SEL_SLM_CONNECTORS',
              routerLink: ['/slm/metric-connectors'],
              requiredPermission: 'Metric connector View',
            },
            {
              label: 'MODULES.SLM.COMPILANCE_REPORT',
              id: 'SEL_SLM_COMPLIANCE',
              routerLink: ['/slm/compliance-report'],
              requiredPermission: 'Reports View',
            },
          ],
        },
        {
          label: 'MODULES.REPORTS.REPORTS',
          icon: 'assignment',
          id: 'SEL_REPORT',
          items: [
            {
              label: 'MODULES.REPORTS.VIEW_REPORTS',
              id: 'SEL_VIEW_REPORTS',
              routerLink: ['/reports/view-reports'],
              requiredPermission: 'Reports View',
            },
            {
              label: 'SOC',
              id: 'SEL_SOC',
              routerLink: ['/reports/soc'],
            },
          ],
        },
        {
          label: 'MODULES.DATA.DATA',
          icon: 'folder',
          id: 'SEL_DATA',
          items: [
            {
              label: 'MODULES.DATA.HIERARCHIES.LABEL',
              id: 'SEL_DATA_VIEW_HIERARCHY',
              routerLink: ['/data/hierarchies'],
              requiredPermission: 'Hierarchy View',
            },
            {
              label: 'MODULES.DATA.UPLOADS',
              id: 'SEL_UPLOADS',
              routerLink: ['/data/upload'],
              requiredPermission: 'Uploads View',
            },
            {
              label: 'MODULES.DATA.CALCULATIONS.LABEL',
              id: 'SEL_CALCULATIONS',
              routerLink: ['/data/calculations'],
              requiredPermission: 'Calculations View',
            },
          ],
        },
        {
          label: 'MODULES.DISTRIBUTIONS.DISTRIBUTIONS',
          icon: 'location_searching',
          id: 'SEL_DISTRIBUTION',
          items: [
            {
              label: 'MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.LABEL',
              id: 'SEL_VIEW_DISTRIBUTION_TEMPLATES',
              routerLink: ['/distributions/view-distributions'],
              requiredPermission: 'Distributions Admin View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.DISTRIBUTE.LABEL',
              id: 'SEL_DISTRIBUTION_DISTRIBUTIONS',
              routerLink: ['/distributions/distribute'],
              requiredPermission: 'Distributions View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.TEMPLATES.LABEL',
              id: 'SEL_DISTRIBUTION_TEMPLATE',
              routerLink: ['/distributions/templates'],
              requiredPermission: 'Distributions Admin View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.HISTORY.LABEL',
              id: 'SEL_DISTRIBUTION_HISTORY',
              routerLink: ['/distributions/history'],
              requiredPermission: 'Distributions View',
            },
          ],
        },
      ],
    },
  ];

  visualDesigner: any = {
    label: 'MODULES.VISUAL_DESIGNER.LABEL',
    id: 'SEL_VISUAL_DESIGNER',
    routerLink: ['/data/visual-designer'],
  };

  constructor(private menuPermission: MenuPermissionsService, private router: Router) {
    this.addVisualDesignerToMenu();
    /**
     * Check if the selected route is the part of  menu items if
     * if route path is availble in menu then activate the menu and sub menu
     */
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const activeRouteWithoutParams = event.url.split('?')[0];

        this.selectedMenu = null;
        this.selectedSubmenu = null;
        this.model[0].items.forEach((parentMenu: any) => {
          parentMenu.items.forEach((menuRoute: any) => {
            if (menuRoute?.routerLink[0] === activeRouteWithoutParams) {
              this.selectedSubmenu = menuRoute;
              this.selectedMenu = parentMenu;
              this.onMenuItemClick(parentMenu);
            }
          });
        });
      }
    });
  }

  addVisualDesignerToMenu(): void {
    if (this.menuPermission.isNotTSLA()) {
      for (let index = 0; index <= this.model[0].items.length - 1; index -= -1) {
        const hasVisualDesigner = this.model[0].items[index].items.some(
          (item: any) => item.id === 'SEL_VISUAL_DESIGNER'
        );

        if (this.model[0].items[index].id === 'SEL_DATA' && !hasVisualDesigner) {
          this.model[0].items[index].items.push(this.visualDesigner);
        }
      }
    }
  }

  ngOnInit(): void {
    this.addVisualDesignerToMenu();
  }

  onMenuItemClick(item: any): void {
    this.selectedMenu = item;
    this.menuItemClicked.emit({ item, activeSubMenu: this.selectedSubmenu }); // Emit the selected menu item to the parent

    // Check if the selected menu item has sub-items and if the first item has a routerLink
    // if (item.items && item.items.length > 0) {
    //   const defaultSubmenuItem = item.items[0]; // Get the first submenu item
    //   if (defaultSubmenuItem.routerLink) {
    //     this.router.navigate(defaultSubmenuItem.routerLink); // Navigate to the first submenu item
    //   }
    // }
  }
}
