<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>
    MODULES.CONTRACTS.DUPLICATE_CONTRACT
  </h5>

  <p-divider styleClass="mb-0"></p-divider>
</div>
<div class="px-3 mx-1">
  <div class="d-flex flex-column gap-2 mt-2" *ngIf="canSeeTreeView">
    <h5>
      <span translate>MODULES.CONTRACTS.COPY_OF</span>
      {{ viewContrcatData.contractName || '' }}
    </h5>

    <div class="d-flex justify-content-between align-items-center mb-2">
      <div>
        <span class="fw-bold" translate
          >MODULES.CONTRACTS.SELECTION_PERIOD</span
        >
        <span> :</span>
        {{
          contractForm.get('selectionStartDate')?.value | date : 'yyyy-MM-dd'
        }}
        <span translate>MODULES.CONTRACTS.TO</span>
        {{ contractForm.get('selectionEndDate')?.value | date : 'yyyy-MM-dd' }}
      </div>

      <div class="d-flex align-items-center">
        <p-dropdown
          [options]="dropdownOptions"
          [(ngModel)]="selectedValue"
          [disabled]="!isDropdownEnabled"
          (onChange)="statusSelector($event)"
          placeholder="{{ 'MODULES.CONTRACTS.STATUS_UPDATE' | translate }}"
          class="p-dropdown-sm me-2"
        >
        </p-dropdown>

        <!-- Existing Clear Filter Button -->
        <button
          pButton
          type="button"
          class="p-button"
          label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
          (click)="clearGrid()"
          [disabled]="!haveFilters"
        ></button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!canSeeTreeView; else treeView">
    <div class="row">
      <form [formGroup]="contractForm">
        <!--FIRST SECTION  -->
        <div class="row g-0">
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="id" translate
                >MODULES.CONTRACTS.CONTRACT_ID</label
              >
            </div>
            <span class="w-100 my-2">
              <input
                #title
                placeholder="{{ 'MODULES.CONTRACTS.CONTRACT_ID' | translate }}"
                class="w-100"
                formControlName="id"
                type="text"
                pInputText
              />
            </span>
            <div *ngIf="contractForm.get('id')?.touched">
              <small
                *ngIf="contractForm.get('id')?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}'
                }}
              </small>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="id">
                {{ 'MODULES.CONTRACTS.NAME' | translate }}*</label
              >
            </div>
            <span class="w-100 my-2">
              <input
                #title
                placeholder="{{ 'MODULES.CONTRACTS.NAME' | translate }}"
                class="w-100"
                formControlName="contractName"
                type="text"
                pInputText
              />
            </span>
            <div *ngIf="contractForm.get('contractName')?.touched">
              <small
                *ngIf="contractForm.get('contractName')?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>
              <small
                *ngIf="contractForm.get('contractName')?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 30}'
                }}
              </small>
            </div>
          </div>
          <div class="col-4 d-flex">
            <div class="w-100">
              <label class="fw-bold mb-2" for="name"
                >{{ 'MODULES.CONTRACTS.CUSTOMER_NAME' | translate }}*</label
              >
              <span
                class="w-100 p-float-label"
                *ngIf="organizationData?.length"
              >
                <p-treeSelect
                  formControlName="customerName"
                  containerStyleClass="w-100 d-flex tree-select-label"
                  [options]="organizationData"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  placeholder="{{
                    'MODULES.CONTRACTS.CUSTOMER_NAME' | translate
                  }}"
                  (onNodeSelect)="nodeSelect()"
                  (onClear)="onClear('CUSTOMER')"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                >
                  <ng-template let-node pTemplate="default">
                    <div class="d-flex w-100">
                      <i
                        [ngClass]="
                          node.expanded
                            ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                            : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                        "
                      ></i>
                      <div class="my-auto">
                        <h6>{{ node.name }}</h6>
                      </div>
                    </div>
                  </ng-template>

                  <!-- to display selected value -->
                  <ng-template let-node pTemplate="value">
                    {{ node?.name ? node?.name : '' }}
                  </ng-template>
                </p-treeSelect>
                <label for="customerName">{{
                  'MODULES.CONTRACTS.CUSTOMER_NAME' | translate
                }}</label>
              </span>
              <div *ngIf="contractForm.get('customerName')?.touched">
                <small
                  *ngIf="contractForm.get('customerName')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
              <p-skeleton *ngIf="!organizationData?.length"></p-skeleton>
            </div>
          </div>
          <div class="col-4 d-flex">
            <div class="w-100">
              <label class="fw-bold mb-2" for="name"
                >{{ 'MODULES.CONTRACTS.PROVIDER_NAME' | translate }}*</label
              >
              <span
                class="w-100 p-float-label"
                *ngIf="organizationData?.length"
              >
                <p-treeSelect
                  formControlName="providerName"
                  containerStyleClass="w-100 tree-select-label d-flex"
                  [options]="organizationData"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  placeholder="{{
                    'MODULES.CONTRACTS.PROVIDER_NAME' | translate
                  }}"
                  (onNodeSelect)="nodeSelect()"
                  (onClear)="onClear('PROVIDER')"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                >
                  <ng-template let-node pTemplate="default">
                    <div class="d-flex w-100">
                      <i
                        [ngClass]="
                          node.expanded
                            ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                            : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                        "
                      ></i>
                      <div class="my-auto">
                        <h6>{{ node.name }}</h6>
                      </div>
                    </div>
                  </ng-template>
                  <!-- to display selected value -->
                  <ng-template let-node pTemplate="value">
                    {{ node?.name ? node?.name : '' }}
                  </ng-template>
                </p-treeSelect>
                <label for="providerName">{{
                  'MODULES.CONTRACTS.PROVIDER_NAME' | translate
                }}</label>
              </span>
              <div *ngIf="contractForm.get('providerName')?.touched">
                <small
                  *ngIf="contractForm.get('providerName')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
              <small
                *ngIf="contractForm.get('providerName')?.errors?.['invalid']"
                class="text-danger"
              >
                {{
                  'VALIDATION.INVALID_INPUT'
                    | translate : { field_1: 'Customer', field_2: 'Provider' }
                }}
              </small>
              <p-skeleton *ngIf="!organizationData?.length"></p-skeleton>
            </div>
          </div>

          <div class="col-4 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="name" translate
                >MODULES.CONTRACTS.DESCRIPTION</label
              >
            </div>
            <span class="w-100 my-2">
              <textarea
                #desc
                class="w-100"
                placeholder="{{ 'MODULES.CONTRACTS.DESCRIPTION' | translate }}"
                formControlName="description"
                pInputTextarea
                rows="2"
              ></textarea>
            </span>
            <div *ngIf="contractForm.get('description')?.touched">
              <small
                *ngIf="contractForm.get('description')?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 1000}'
                }}
              </small>
            </div>
          </div>
        </div>
        <!-- User defined fields ( CF's) -->
        <h5 translate class="mt-4 px-2" *ngIf="UDFList && UDFList.length > 0">
          MODULES.CONTRACTS.UDF_CONTRACT
        </h5>
        <p-divider styleClass="mb-0"></p-divider>
        <div class="" *ngIf="UDFList && UDFList.length > 0">
          <div class="row g-0">
            <div class="row">
              <app-udf
                [udfInfo]="UDFList"
                (formDataChanged)="onFormDataChanged($event)"
              ></app-udf>
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <!--THIRD SECTION  -->
        <h6 class="my-3" translate>MODULES.VIEW_METRICS.VERSION_DETAILS</h6>
        <div class="row g-0">
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="id" translate
                >MODULES.CONTRACTS.VERSION_NAME</label
              >
            </div>
            <span class="w-100 my-2">
              <input
                #title
                formControlName="version"
                placeholder="Version Name"
                class="w-100"
                type="text"
                pInputText
              />
            </span>

            <div *ngIf="contractForm.get('version')?.touched">
              <small
                *ngIf="contractForm.get('version')?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}'
                }}
              </small>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="sDate" translate
                >{{ 'MODULES.CONTRACTS.START_DATE' | translate }}*</label
              >
            </div>
            <span class="w-100">
              <p-calendar
                placeholder="{{ 'MODULES.CONTRACTS.START_DATE' | translate }}"
                formControlName="startDate"
                styleClass="w-100 p-inputtext-sm"
                [minDate]="minStartDate"
                (onSelect)="onSelectStartDate($event)"
                [readonlyInput]="true"
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
              >
              </p-calendar>
              <div *ngIf="contractForm.get('contractName')?.touched">
                <small
                  *ngIf="contractForm.get('startDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </span>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="eDate" translate
                >{{ 'MODULES.CONTRACTS.END_DATE' | translate }}*</label
              >
            </div>
            <span class="w-100">
              <p-calendar
                placeholder="{{ 'MODULES.CONTRACTS.END_DATE' | translate }}"
                formControlName="endDate"
                styleClass="w-100 p-inputtext-sm"
                [minDate]="minEndDate"
                [maxDate]="maxEndDate"
                [minDate]="minEndDate"
                [maxDate]="maxEndDate"
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
              >
              </p-calendar>
              <div *ngIf="contractForm.get('contractName')?.touched">
                <small
                  *ngIf="contractForm.get('endDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </span>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bold mb-2" for="statement" translate=""
                >MODULES.CONTRACTS.STATEMENT</label
              >
            </div>
            <span class="w-100 my-2">
              <textarea
                #desc
                placeholder="{{ 'MODULES.CONTRACTS.STATEMENT' | translate }}"
                formControlName="statement"
                class="w-100"
                pInputTextarea
                rows="2"
              ></textarea>
            </span>
            <div *ngIf="contractForm.get('statement')?.touched">
              <small
                *ngIf="contractForm.get('statement')?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}'
                }}
              </small>
            </div>
          </div>
        </div>

        <!-- FOURTH SECTION -->
        <h6 class="my-3 display-inline">
          {{ 'MODULES.CONTRACTS.METRIC_RANGE' | translate }}
          <small class="fw-normal pl-2"
            >( {{ 'MODULES.CONTRACTS.TIMELINE_SELECTION' | translate }})</small
          >
        </h6>
        <p-divider styleClass="mb-0"></p-divider>
        <div class="row g-0">
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label for="startDate" class="fw-bold mb-2"
                >{{
                  'MODULES.CONTRACTS.SELECTOR_START_DATE' | translate
                }}*</label
              >
            </div>
            <span class="w-100 my-1">
              <p-calendar
                placeholder="{{
                  'MODULES.VIEW_METRICS.START_DATE' | translate
                }}"
                formControlName="selectionStartDate"
                styleClass="w-100 p-inputtext-sm"
                [minDate]="minStartDate"
                (onSelect)="oncontractSelectStartDate($event)"
                [readonlyInput]="true"
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
              >
              </p-calendar>
              <div *ngIf="contractForm.get('contractName')?.touched">
                <small
                  *ngIf="contractForm.get('selectionStartDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </span>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-between align-items-center">
              <label for="endDate" class="fw-bold mb-2"
                >{{ 'MODULES.CONTRACTS.SELECTOR_END_DATE' | translate }}*</label
              >
            </div>
            <span class="w-100 my-1">
              <p-calendar
                placeholder="{{ 'MODULES.VIEW_METRICS.END_DATE' | translate }}"
                formControlName="selectionEndDate"
                styleClass="w-100 p-inputtext-sm"
                [minDate]="minEndDate"
                [maxDate]="maxEndDate"
                [minDate]="minEndDate"
                [maxDate]="maxEndDate"
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
              >
              </p-calendar>
              <div *ngIf="contractForm.get('contractName')?.touched">
                <small
                  *ngIf="contractForm.get('selectionEndDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </span>
          </div>
        </div>

        <!--buttons-->

        <div class="row mt-2 px-2">
          <ng-container *ngIf="errorMsgList?.length">
            <ul class="ps-3 error-block">
              <li class="text-danger" *ngFor="let msg of errorMsgList">
                {{ msg }}
              </li>
            </ul>
          </ng-container>
          <div class="col-6 col-sm-6">
            <button
              pButton
              type="button"
              label="{{ 'ABOUT.CLOSE' | translate }}"
              class="p-button-outlined"
              style="width: 110px"
              (click)="closeContract()"
            ></button>
          </div>
          <div class="col-6 col-sm-6 d-flex justify-content-end">
            <button
              class="p-button"
              pButton
              type="button"
              label="{{ 'MODULES.CONTRACTS.DUPLICATE_SAVE' | translate }}"
              (click)="saveAndCopyMetric()"
            ></button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <!-- AG Grid Table -->
  <ng-template #treeView>
    <ag-grid-angular
      class="ag-theme-balham"
      style="width: 100%; height: calc(100vh - 200px)"
      [columnDefs]="headers"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (sortChanged)="onSortChange()"
      (filterModified)="onFilterModified($event)"
      (rowSelected)="onRowSelected($event)"
      #agGrid
    >
    </ag-grid-angular>
    <ng-container *ngIf="errorMsgList?.length">
        <ul class=" ps-3 error-block">
            <li class="text-danger" *ngFor="let msg of errorMsgList">
                {{ msg }}
            </li>
        </ul>
    </ng-container>
    <div class="row mt-3">
      <div class="col-6 col-sm-6">
        <button
          pButton
          type="button"
          label="{{ 'ABOUT.CLOSE' | translate }}"
          class="p-button-outlined"
          style="width: 110px"
          (click)="closeContract()"
        ></button>
      </div>
      <div class="col-6 col-sm-6 d-flex justify-content-end">
        <button
          class="p-button"
          pButton
          type="button"
          label="{{ 'MODULES.CONTRACTS.SAVE' | translate }}"
          style="width: 110px"
          (click)="validateContract(!canSeeTreeView)"
        ></button>
      </div>
    </div>
  </ng-template>
</div>
