import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-udf',
  templateUrl: './udf.component.html',
  styleUrls: ['./udf.component.scss'],
})
export class UdfComponent implements OnInit, OnChanges {
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() udfInfo: any;

  dynamicForm!: FormGroup;

  udfSelectedData: any;

  uploadedFiles: File[] = [];

  errorMsgList: any = [];

  valueSetList: any;

  metricObjectFields: any[] = [];

  metricVersionObjectFields: any[] = [];

  formControlsInitialized = false;

  loading = true;

  valueSetMap: any;

  nullData: any = { key: '', title: ' ' };

  constructor(
    private formBuilder: FormBuilder,
    private udfService: UdfService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.udfService.viewUDFData.subscribe((res: any) => {
      if (res) {
        this.udfSelectedData = res;
      }
    });
  }

  ngOnChanges(): void {
    this.buildForm();
  }

  buildForm(): void {
    // Check if the dynamicForm FormGroup exists
    if (this.dynamicForm) {
      // Iterate over the keys (control names) of the dynamicForm's controls
      Object.keys(this.dynamicForm.controls).forEach(key => {
        // Remove each control from the dynamicForm FormGroup
        this.dynamicForm.removeControl(key);
      });

      // Set the formControlsInitialized flag to false, indicating that form controls are being reset
      this.formControlsInitialized = false;

      // Re-enable the entire dynamicForm FormGroup
      this.dynamicForm.enable();
    }

    if (this.udfInfo && this.udfInfo.length > 0) {
      const valueSetIds: number[] = this.udfInfo
        .filter((udfData: any) => udfData.objectFieldType === 'Value Set' && udfData.valueSetId)
        .map((udfData: any) => udfData.valueSetId);

      if (valueSetIds.length > 0) {
        const valueSetRequests = valueSetIds.map(id =>
          this.udfService.getValueSet(id).pipe(map((res: any) => ({ id, data: res })))
        );

        forkJoin(valueSetRequests).subscribe((responses: any[]) => {
          this.valueSetMap = responses.reduce((acc, curr) => {
            acc[curr.id] = [this.nullData, ...curr.data];

            return acc;
          }, {});

          this.buildFormInsideSubscription(); // invoke buildFormInsideSubscription after receiving all API responses
        });
      } else {
        this.buildFormInsideSubscription(); // invoke buildFormInsideSubscription directly if there are no valueSetIds
      }
    }
  }

  buildFormInsideSubscription(): void {
    const formControlsConfig: { [key: string]: any } = {};

    if (this.udfInfo && this.udfInfo.length > 0) {
      this.udfInfo.forEach((data: any) => {
        let validators: any = [];

        if (!data.fieldName || !data.objectFieldType) {
          // Skip this iteration if fieldName or objectFieldType is null or undefined
          return;
        }

        validators = this.setControlsValidators(data);
        formControlsConfig[data.fieldName] = [null, validators];
      });
    }

    // Initialize dynamicForm before assigning formControlsConfig

    this.dynamicForm = this.formBuilder.group(formControlsConfig);
    this.formControlsInitialized = true;
    /**
     * In case of edit if the status is in  published mode or revoked mode  , then in that scenario udf  form will be in disabled state
     */
    if (
      this.udfSelectedData &&
      (this.udfSelectedData.statusValue === 'Published' || this.udfSelectedData.statusValue === 'Revoked')
    ) {
      this.dynamicForm.disable();
    } else {
      this.dynamicForm.enable();
    }

    /** Patch value in case of select along with date  and date-time format type  */

    this.udfInfo.forEach((data: any) => {
      if (data.objectFieldType === 'Value Set' && this.valueSetMap[data.valueSetId]) {
        const valueSetArray = this.valueSetMap[data.valueSetId];

        // Find the element where the title matches the data.value
        const matchingElement = valueSetArray.find((element: any) => element.title === data.value);

        if (matchingElement) {
          // Patch the selected value to the form control
          this.dynamicForm.get(data.fieldName)?.patchValue(matchingElement.key);
        }
      } else if (
        data.value &&
        (data.objectFieldType === 'Date and Time' ||
          data.objectFieldType === 'Date' ||
          data.objectFieldType === 'Month' ||
          data.objectFieldType === 'Year')
      ) {
        // eslint-disable-next-line no-nested-ternary
        const valueToPatch = data.value ? (Number.isNaN(Date.parse(data.value)) ? '' : new Date(data.value)) : '';

        this.dynamicForm.get(data.fieldName)?.patchValue(valueToPatch);
      } else if (data.value && data.objectFieldType === 'Number') {
        // eslint-disable-next-line no-nested-ternary
        const valueToPatch = !Number.isNaN(Number(data.value)) ? data.value : null;

        this.dynamicForm.get(data.fieldName)?.patchValue(valueToPatch);
      } else {
        this.dynamicForm.get(data.fieldName)?.patchValue(data.value);
      }
      this.simulateDataAsPerAPI(this.dynamicForm.value);
    });

    // Modify the valueChanges subscription once the data has been there

    if (this.dynamicForm) {
      this.dynamicForm?.valueChanges.subscribe(value => {
        // Emit the payload when the form value changes on user interaction
        this.simulateDataAsPerAPI(value);
      });
    }
  }

  setControlsValidators(field: any): any {
    const validators: any = [];

    if (field.isMandatory) {
      validators.push(Validators.required);
    }
    if (field.maximumValue) {
      validators.push(Validators.maxLength(field.maximumValue));
    }
    if (field.minimumValue) {
      validators.push(Validators.maxLength(field.minimumValue));
    }
    if (field.patternValidation) {
      validators.push(Validators.pattern(field.patternValidation));
    }

    return validators;
  }

  // Define arrays to store metricObjectFields and metricVersionObjectFields
  simulateDataAsPerAPI(value: any): any {
    let field: any;
    const newMetricObjectFields: any[] = [];
    const newmetricVersionObjectFields: any[] = [];

    // Iterate through the form values
    Object.keys(value).forEach(key => {
      const controlValue = value[key];

      // Find the corresponding udfData based on fieldName
      const udfData = this.udfInfo.find((data: any) => data.fieldName === key);

      // If udfData is not found, skip this key
      if (!udfData) {
        return;
      }

      // Create metric object field as it will be mapped as per parent from UDf
      // merge if objectfieldDescription id is available - this happen in case of edit
      if (udfData.objectFieldDescrId) {
        field = {
          objectFieldDescrId: udfData.objectFieldDescrId,
          id: udfData.id,
        };
      } else {
        field = {
          objectFieldDescrId: udfData.id,
        };
      }

      // Set the value based on objectFieldType using switch statement
      switch (udfData.objectFieldType) {
        case 'Value Set':
          // For Value Set, use valueSetRowId instead of value
          field.valueSetKey = controlValue;
          break;
        case 'Date and Time':
          field.value = this.datePipe.transform(controlValue, 'yyyy-MM-dd H:mm:ss');
          break;
        case 'Date':
        case 'Month':
        case 'Year':
          field.value = this.datePipe.transform(controlValue, 'yyyy-MM-dd');
          break;
        default:
          // For other cases, set the value directly
          field.value = controlValue;
          break;
      }

      // Push the field to the corresponding array based on parentType
      if (udfData.parentType === 'Metric' || udfData.parentType === 'Hierarchy' || udfData.parentType === 'Contract') {
        newMetricObjectFields.push(field);
      } else if (udfData.parentType === 'MetricVersion') {
        newmetricVersionObjectFields.push(field);
      }
    });

    this.metricObjectFields = [...newMetricObjectFields];

    // merge newMetricObjectFields with exisiting metriicObjectFields
    this.metricVersionObjectFields = [...newmetricVersionObjectFields];

    // Construct the payload with metricObjectFields and metricVersionObjectFields
    const payload = {
      metricObjectFields: [...this.metricObjectFields],
      metricVersionObjectFields: [...this.metricVersionObjectFields],
    };

    this.loading = false;
    this.formDataChanged.emit(payload);
  }

  /** Upload  */
  uploadReportKPI(event: any): void {
    this.uploadedFiles = [];
    this.errorMsgList = [];
    const fileList: FileList = event.files;

    for (let i = 0; i < fileList.length; i += 1) {
      this.uploadedFiles.push(fileList[i]);
      // this.isImageOrCSV(event.files);
    }
  }

  getCustomChooseLabel(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.UPLOAD_CSV');
  }

  getInvalidFileTypeMessageSummary(): string {
    return '';
  }

  getInvalidFileTypeMessageDetail(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.FILE_FORMAT');
  }

  clearUploadValue(event: any): void {
    if (event) {
      this.uploadedFiles = [];
      this.errorMsgList = [];
    }
  }

  /**
   * Returns the appropriate view type for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The view type for the date picker ('month', 'year', 'datetime', 'date').
   */
  getViewType(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'month';
      case 'Year':
        return 'year';
      default:
        return 'date';
    }
  }

  /**
   * Returns the appropriate date format for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The date format for the date picker (e.g., 'MM', 'yyyy', 'yyyy-MM-dd H:mm:ss', 'yyyy-MM-dd').
   */
  getDateFormat(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'MM';
      case 'Year':
        return 'yyyy';
      case 'Date and Time':
        return 'yyyy-MM-dd H:mm:ss';
      default:
        return 'yyyy-MM-dd';
    }
  }

  /** End of Upload  */

  emitFormData(): void {
    this.formDataChanged.emit(this.dynamicForm.value);
  }
}
