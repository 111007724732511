<ng-container>
    <!-- action button disable if the status is in progress -->
    <button class="p-link" [disabled]="actionMenuItem.state === 'In Progress'" pTooltip="{{
        actionMenuItem.state !== 'In Progress'
          ? ('MODULES.DATA.CALCULATIONS.TOOLTIP' | translate)
          : ''
      }}" tooltipPosition="top" (click)="getDetails()">
        <img [ngClass]="{ 'pi pi-spin': actionMenuItem.state === 'In Progress' }"
            src="assets/actions/{{getIconKey(actionMenuItem.state)}}" alt="action-icon" />
    </button>
</ng-container>