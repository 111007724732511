import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { debounceTime, interval, Subscription, takeWhile } from 'rxjs';

import { FavMenuService } from '@app/shared/services/fav-menu.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, OnDestroy {
  @ViewChild('menu', { read: ElementRef }) langMenu!: ElementRef;

  userMenu: any[] = [];

  roleMenu: any[] = [];

  isVisible = false;

  isFirstActive = true;

  isPolling = false;

  private subscriptions: Subscription = new Subscription();

  private pollingSubscription: Subscription | undefined;

  constructor(private favMenuService: FavMenuService, private eRef: ElementRef) {
    this.subscriptions.add(
      this.favMenuService.updatedMenuItems.subscribe((res: any) => {
        if (res) {
          this.getFavMenu();
        }
      })
    );

    this.subscriptions.add(
      this.favMenuService.isFavMenuOpen$.subscribe((isMenuOpen: any) => {
        this.isVisible = isMenuOpen || false;
      })
    );

    this.subscriptions.add(
      this.favMenuService.loadFavMenu$.pipe(debounceTime(300)).subscribe(() => {
        this.getFavMenu();
      })
    );
  }

  startPolling(): void {
    this.isPolling = true;
    this.pollingSubscription = interval(30000) // 30 seconds in milliseconds
      .pipe(takeWhile(() => !this.isDestroyed())) // Stop polling if the component is destroyed
      .subscribe(() => {
        this.favMenuService.loadFavMenu();
      });
  }

  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = undefined;
    }
  }

  private isDestroyed(): boolean {
    return !this.pollingSubscription;
  }

  @HostListener('document:click', ['$event'])
  closeDiv(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target) && this.isVisible) {
      this.isVisible = false;
    }
  }

  getFavMenu(): void {
    this.favMenuService.getFavMenu().subscribe((res: any) => {
      this.userMenu = res.USER_FAVORITE;
      this.roleMenu = res.ROLE_FAVORITE;
    });
  }

  ngOnInit(): void {
    this.startPolling();
  }

  toggleFavMenu(): void {
    if (!this.isVisible) {
      this.favMenuService.loadFavMenu();
    }
    this.isVisible = !this.isVisible;
  }

  activateFirst(): void {
    this.isFirstActive = true;
  }

  activateSecond(): void {
    this.isFirstActive = false;
  }

  ngOnDestroy(): void {
    this.stopPolling();
    this.subscriptions.unsubscribe();
  }
}
