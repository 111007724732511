<div class="layout-sidebar">
  <ng-container *ngFor="let item of model[0].items">
    <ng-container *hasPermission="item.menuPermission">
      <ng-container *ngIf="!item.separator">
        <div
          class="text-center my-5 icon-text-white"
          role="button"
          (click)="onMenuItemClick(item)"
          [ngClass]="{ active: selectedMenu === item }"
        >
          <i class="material-symbols-outlined mb-2"> {{ item.icon }} </i>
          <p class="mb-0" translate>{{ item.label }}</p>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
