<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <button
        pButton
        type="button"
        label="{{ 'MODULES.CONTRACTS.ADD_CONTRACT' | translate }}"
        [disabled]="!contractPermission.hasAddContract()"
        class="p-button"
        (click)="isCreateContract = !isCreateContract"
      ></button>
    </div>
    <div class="col-6 text-right">
      <button
        pButton
        type="button"
        class="p-button"
        label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()"
        [disabled]="!haveFilters"
      ></button>
    </div>
  </div>
  <div class="row">
    <ng-container>
      <ag-grid-angular
        class="ag-theme-balham custom-ag-table"
        [columnDefs]="headers"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClick($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        #agGrid
      >
      </ag-grid-angular>
    </ng-container>
  </div>

  <!-- view contract details -->
  <p-sidebar
    [(visible)]="isContractviewEnabled"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    *ngIf="isContractviewEnabled"
  >
    <app-view-contract
      (closeClicked)="onCloseViewContrcat($event)"
    ></app-view-contract>
  </p-sidebar>

  <!-- Create new contract -->
  <p-sidebar
    [(visible)]="isCreateContract"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isCreateContract"
  >
    <app-add-contract
      (closeClicked)="isCreateContract = $event"
    ></app-add-contract>
  </p-sidebar>

  <!-- create contract version -->
  <p-sidebar
    [(visible)]="isCreateversion"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isCreateversion"
  >
    <app-add-contract-version
      (closeClicked)="isCreateversion = $event"
    ></app-add-contract-version>
  </p-sidebar>

  <!-- edit contract version -->
  <p-sidebar
    [(visible)]="isEditVersionEnabled"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isEditVersionEnabled"
  >
    <app-edit-contract-version
      (closeClicked)="isEditVersionEnabled = $event"
    ></app-edit-contract-version>
  </p-sidebar>

  <p-sidebar
    [(visible)]="isDuplicateContrcat"
    position="right"
    styleClass="p-sidebar-lg right-sidebar-view"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isDuplicateContrcat"
    [style]="{ width: use60Percent ? '60%' : '75%' }"
  >
    <app-duplicate-contract
      [viewContrcatData]="viewContrcatData"
      (closeClicked)="isDuplicateContrcat = $event"
    ></app-duplicate-contract>
  </p-sidebar>
</div>
