<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.METRICS.ALL_DETAILS</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="d-flex flex-row-reverse text-right px-4 py-0">
  <ng-container
    *ngIf="
      viewUdfList?.length &&
      !hasPublishedOrRevokedVersion() &&
      !isEditVersionEnabled &&
      !isAddVersionEnabled
    "
  >
    <div class="col-2">
      <button
        pButton
        type="button"
        class="p-button-outlined"
        style="width: 110px"
        icon="pi pi-file-edit"
        label="{{ 'MODULES.METRICS.EDIT' | translate }}"
        (click)="triggerEditVersion(); isEditUDFEnabled = true"
      ></button>
    </div>
  </ng-container>
</div>

<!-- Metric Details -->
<div class="px-4">
  <div class="row mt-2">
    <!-- Metric ID-->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.METRIC_ID </label>
      <div class="mt-1">
        {{ viewData?.metricId }}
        <p-skeleton *ngIf="!viewData?.metricId"></p-skeleton>
      </div>
    </div>

    <!-- Metric name -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.NAME </label>
      <div class="mt-1">
        {{ viewData?.metricName }}
        <p-skeleton *ngIf="!viewData?.metricName"></p-skeleton>
      </div>
    </div>

    <!--Metric connector-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate> MODULES.METRICS.METRIC_CONNECTOR</label>

      <div class="mt-1">
        {{ viewData?.metricConnector }}
        <p-skeleton *ngIf="!viewData?.metricConnector"></p-skeleton>
      </div>
    </div>

    <!--Contract name-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate>
        MODULES.VIEW_METRICS.CONTRACT_NAME</label
      >

      <div class="mt-1">
        {{ viewData?.contractName }}
        <p-skeleton *ngIf="!viewData?.contractName"></p-skeleton>
      </div>
    </div>

    <!--Customer-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate>MODULES.METRICS.CUSTOMER</label>

      <div class="mt-1">
        {{ viewData?.customerName }}
        <p-skeleton *ngIf="!viewData?.customerName"></p-skeleton>
      </div>
    </div>

    <!--Provider-->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.METRICS.PROVIDER</label>

      <div class="mt-1">
        {{ viewData?.provider }}
        <p-skeleton *ngIf="!viewData?.provider"></p-skeleton>
      </div>
    </div>

    <!--Business service-->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate>
        MODULES.VIEW_METRICS.BUSINESS_SERVICE</label
      >

      <div class="mt-1">
        {{ viewData?.businessService }}
        <p-skeleton *ngIf="!viewData?.businessService"></p-skeleton>
      </div>
    </div>

    <!-- Condition -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.CONDITION</label>

      <div class="mt-1">
        {{ viewData?.conditionName }}
        <p-skeleton *ngIf="!viewData?.conditionName"></p-skeleton>
      </div>
    </div>

    <!-- Format -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate>MODULES.VIEW_METRICS.FORMAT</label>

      <div class="mt-1">
        {{ viewData?.targetFormatName }}
        <p-skeleton *ngIf="!viewData?.targetFormatName"></p-skeleton>
      </div>
    </div>

    <!-- Sub-format -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.SUB_FORMAT </label>

      <div class="mt-1">
        {{ viewData?.formatName }}
        <p-skeleton *ngIf="!viewData?.formatName"></p-skeleton>
      </div>
    </div>

    <!-- Period -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.PERIOD</label>

      <div class="mt-1">
        {{ viewData?.periodName }}
        <p-skeleton *ngIf="!viewData?.periodName"></p-skeleton>
      </div>
    </div>

    <!--Description-->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate>
        MODULES.VIEW_METRICS.DESCRIPTION
      </label>
      <div class="mt-1">
        {{ viewData?.description }}
        <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
      </div>
    </div>

    <!-- Statement -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate>MODULES.VIEW_METRICS.STATEMENT</label>

      <div class="mt-1">
        {{ viewData?.statement }}
        <p-skeleton *ngIf="!viewData?.statement"></p-skeleton>
      </div>
    </div>
  </div>
</div>

<!-- UDF Metric Details -->
<ng-container *ngIf="!isEditUDFEnabled">
  <h5 translate class="mt-2 px-4">MODULES.VIEW_METRICS.UDF_METRIC</h5>
  <p-divider styleClass="mb-0"></p-divider>

  <div class="px-4">
    <div class="row mt-2">
      <ng-container *ngFor="let udfField of viewUdfList">
        <div class="col-4 mb-1">
          <label class="fw-bold text-break" translate>
            {{ udfField.name }}</label
          >
          <div class="mt-1">
            <span class="text-break">
              {{ udfField?.formattedValue ? udfField?.formattedValue : 'N/A' }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!viewUdfList">
  <div class="row px-4 mt-2">
    <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
      <p-skeleton></p-skeleton>
    </div>
  </div>
</ng-container>

<!-- Version Details -->
<div class="mt-2" *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <h5 class="header-color py-3 px-4" translate>
    MODULES.VIEW_METRICS.VERSION_DETAILS
  </h5>
  <p-divider styleClass="my-0"></p-divider>
</div>

<div class="px-4" *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <div class="row mt-2">
    <div class="col-6 col-sm-6">
      <label class="fw-bold mb-2" translate>
        MODULES.VIEW_METRICS.SELECT_VERSION
      </label>
      <ng-container *ngIf="viewVersionList.length">
        <button
          pButton
          type="button"
          class="w-full p-button-outlined custom-btn-outline"
          (click)="op.toggle($event)"
        >
          <div class="w-100 text-center">
            <span class="pe-2"
              >{{
                selectedVersion
                  ? selectedVersion.versionName
                  : viewVersionList[0].versionName
              }}
            </span>
            <span
              [ngClass]="
                selectedVersion.statusValue === 'Published'
                  ? 'success-chip'
                  : selectedVersion?.statusValue === 'Draft'
                  ? 'warning-chip'
                  : 'danger-chip'
              "
            >
              {{
                selectedVersion
                  ? selectedVersion.statusValue
                  : viewVersionList[0].statusValue
              }}
            </span>
          </div>
          <i class="pi pi-angle-down"></i>
        </button>

        <p-overlayPanel #op [showCloseIcon]="true" styleClass="custom-overlay">
          <ng-template pTemplate>
            <!-- status filter -->
            <form [formGroup]="createMetricForm">
              <div class="mb-3">
                <span class="fw-bold pe-3 ps-2 align-middle" translate
                  >MODULES.VIEW_METRICS.FILTER_BY</span
                >
                <ng-container
                  formArrayName="statusFilter"
                  *ngFor="let check of statusList; let i = index"
                >
                  <p-checkbox
                    [label]="check.label"
                    [binary]="true"
                    class="align-middle px-2"
                    [labelStyleClass]="check.class"
                    [formControlName]="i"
                    (onChange)="applyStatusFilter($event, check.label)"
                  >
                  </p-checkbox>
                </ng-container>
              </div>
            </form>

            <p-table
              #paginationReset
              styleClass="p-datatable-sm"
              [value]="filteredVersion"
              selectionMode="single"
              [(selection)]="selectedVersion"
              (onRowSelect)="onClosePanel($event)"
              (onRowUnselect)="onClosePanel($event)"
              [paginator]="true"
              [rows]="10"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{{
                'MODULES.PAGINATION.SHOWING' | translate
              }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last} {{
                'MODULES.PAGINATION.OF' | translate
              }} {{ filteredVersion.length }} {{
                'MODULES.PAGINATION.ENTRIES' | translate
              }}"
              [scrollHeight]="'calc(100vh - 180px)'"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 110px" translate>
                    MODULES.VIEW_METRICS.VERSION_NAME
                  </th>
                  <th style="width: 90px" translate>MODULES.METRICS.STATUS</th>
                  <th style="width: 100px" translate>
                    MODULES.VIEW_METRICS.START_DATE
                  </th>
                  <th style="width: 100px" translate>
                    MODULES.VIEW_METRICS.END_DATE
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-version>
                <tr [pSelectableRow]="rowData">
                  <td>{{ version.versionName }}</td>
                  <td>
                    <span
                      [ngClass]="
                        version.statusValue === 'Published'
                          ? 'success-chip'
                          : version.statusValue === 'Draft'
                          ? 'warning-chip'
                          : 'danger-chip'
                      "
                    >
                      {{ version.statusValue }}
                    </span>
                  </td>
                  <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                  <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                </tr>
              </ng-template>
              <!-- no records msg -->
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td class="text-center" [attr.colSpan]="4">
                    {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
        </p-overlayPanel>
      </ng-container>
      <p-skeleton *ngIf="!viewVersionList.length"></p-skeleton>
    </div>

    <div class="col-6 col-sm-6 p-4 mt-2 text-right">
      <ng-container *ngIf="hasPublishedOrRevokedVersion()">
        <button
          pButton
          type="button"
          class="p-button-outlined"
          style="width: 110px"
          [disabled]="
            selectedVersion && selectedVersion.statusValue !== 'Draft'
          "
          (click)="triggerEditVersion()"
          icon="pi pi-file-edit"
          label="{{ 'MODULES.METRICS.EDIT' | translate }}"
        ></button>
      </ng-container>
      <button
        pButton
        type="button"
        class="p-button-outlined ml-2"
        icon="pi pi-plus-circle"
        style="width: 110px"
        (click)="triggerAddVersion()"
        label="{{ 'MODULES.METRICS.Add' | translate }}"
        *ngIf="viewUdfList?.length"
      ></button>
    </div>

    <div class="col-4 mb-1">
      <label class="fw-bold" translate>
        MODULES.VIEW_METRICS.VERSION_NAME
      </label>

      <div class="mt-1">
        <span *ngIf="createMetricForm.get('versionName')?.value">
          {{ createMetricForm.get('versionName')?.value }}
        </span>
        <p-skeleton
          *ngIf="!createMetricForm.get('versionName')?.value"
        ></p-skeleton>
      </div>
    </div>

    <!-- Target -->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.TARGET</label>

      <div class="mt-1">
        {{ createMetricForm.get('target')?.value }}
        <p-skeleton *ngIf="!createMetricForm.get('target')?.value"></p-skeleton>
      </div>
    </div>

    <!-- Expected-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.EXPECTED</label>

      <div class="mt-1">
        {{ createMetricForm.get('expected')?.value }}
        <p-skeleton
          *ngIf="!createMetricForm.get('expected')?.value"
        ></p-skeleton>
      </div>
    </div>

    <!--Start date-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.START_DATE</label>

      <div class="mt-1">
        {{ createMetricForm.get('startDate')?.value | date : 'yyyy-MM-dd' }}
        <p-skeleton
          *ngIf="!createMetricForm.get('startDate')?.value"
        ></p-skeleton>
      </div>
    </div>

    <!--End date-->
    <div class="col-4 mb-1" translate>
      <label class="fw-bold" translate> MODULES.VIEW_METRICS.END_DATE</label>

      <div class="mt-1">
        {{ createMetricForm.get('endDate')?.value | date : 'yyyy-MM-dd' }}
        <p-skeleton
          *ngIf="!createMetricForm.get('endDate')?.value"
        ></p-skeleton>
      </div>
    </div>

    <!-- version statement -->
    <div class="col-4 mb-1">
      <label class="fw-bold" translate> MODULES.CONTRACTS.STATEMENT</label>

      <div class="mt-1">
        {{ createMetricForm.get('metricVersionStatement')?.value }}
        <p-skeleton
          *ngIf="!createMetricForm.get('metricVersionStatement')?.value"
        ></p-skeleton>
      </div>
    </div>
  </div>
</div>

<!-- UDF Version Details -->
<ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <h5 translate class="mt-4 px-4">MODULES.VIEW_METRICS.UDF_VERSION</h5>
  <p-divider styleClass="mb-0"></p-divider>

  <div class="px-4">
    <div class="row mt-2">
      <ng-container *ngFor="let udfField of versionUdfList">
        <div class="col-4 mb-1">
          <label class="fw-bold" translate> {{ udfField.name }}</label>

          <div class="mt-1">
            <span class="text-break">
              {{ udfField?.formattedValue ? udfField?.formattedValue : 'N/A' }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isEditVersionEnabled">
  <app-edit-version></app-edit-version>
</ng-container>

<ng-container *ngIf="isAddVersionEnabled">
  <app-add-version></app-add-version>
</ng-container>

<ng-container *ngIf="!versionUdfList">
  <div class="row px-4 mt-2">
    <div class="col-4" *ngFor="let item of [].constructor(6)">
      <p-skeleton></p-skeleton>
    </div>
  </div>
</ng-container>

<!-- btn section -->
<div class="px-4" *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <div class="row mt-2">
    <!-- api error msg block -->
    <ng-container *ngIf="errorMsgList?.length">
      <div class="col-12 error-block">
        <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
      </div>
    </ng-container>

    <div class="col-6 col-sm-6">
      <button
        pButton
        type="button"
        label="{{ 'ABOUT.CLOSE' | translate }}"
        class="p-button-outlined"
        style="width: 110px"
        (click)="closeViewMetric()"
      ></button>
    </div>
    <div
      class="col-6 col-sm-6 text-right"
      *ngIf="selectedVersion && selectedVersion.statusValue === 'Draft'"
    >
      <button
        pButton
        type="submit"
        label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
        [disabled]="!metricPermission.hasPublishMetricVersion()"
        class="p-button"
        style="width: 110px"
        (click)="cofirmPublish($event)"
      ></button>
    </div>
    <div
      class="col-6 col-sm-6 text-right"
      *ngIf="selectedVersion && selectedVersion.statusValue === 'Published'"
    >
      <button
        pButton
        type="submit"
        label="{{ 'MODULES.CREATE_METRIC.REVOKE' | translate }}"
        [disabled]="!metricPermission.hasRevokeMetricVersion()"
        class="p-button"
        style="width: 110px"
        (click)="cofirmRevoke($event)"
      ></button>
    </div>
  </div>
</div>

<p-confirmPopup></p-confirmPopup>
