import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CellRendererService {
  public externalVariableClicked: EventEmitter<{ id: number; totalVar: number }> = new EventEmitter();

  public recipientsDetailsClicked: EventEmitter<{ recipients: string[]; type: string }> = new EventEmitter();

  public downloadDistributeDocClicked: EventEmitter<{ id: number; docId: string }> = new EventEmitter();

  public triggerGenerateActionClicked: EventEmitter<{ id: number; params: any }> = new EventEmitter();

  public triggerReleaseActionClicked: EventEmitter<{ id: number; params: any }> = new EventEmitter();

  public triggerTestReleaseActionClicked: EventEmitter<{ id: number; params: any }> = new EventEmitter();

  public viewFailedDistributeclicked: EventEmitter<{ status: string[]; type: string }> = new EventEmitter();
  //     public generateClicked: EventEmitter<number> = new EventEmitter();
  //   public releaseClicked: EventEmitter<any> = new EventEmitter();
  //   public sendTestEmailClicked: EventEmitter<any> = new EventEmitter();

  private renderer: Renderer2;

  constructor(
    private datePipe: DatePipe,
    rendererFactory: RendererFactory2,
    private translate: TranslateService,
    private http: HttpClient
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // common cell rendering
  reportCellRenderer(params: any, isRowSpan = false): any {
    const mainSpan: any = document.createElement('span');

    mainSpan.style.display = 'block';
    // mainSpan.style.minWidth = '200px';
    mainSpan.style.height = 'inherit';
    const img: any = document.createElement('img');

    img.className = 'cursor-pointer';
    mainSpan.innerHTML = params.value ? params.value : '';

    // if (params.column.colId === 'target') {
    //   mainSpan.innerHTML = '';
    //   this.getTargetCellData(params, mainSpan, img, isRowSpan);
    // }

    if (params.colDef.headerName === 'Status' || params.colDef.headerName === 'Version Status') {
      mainSpan.innerHTML = '';
      this.statusCellData(params, mainSpan);
    }

    if (params.colDef.field === 'coverage') {
      mainSpan.innerHTML = '';
      this.coverageCellData(params, mainSpan, img);
    }

    if (params.data.attributes.length) {
      params.data.attributes.forEach((property: any) => {
        if (params.column.colId === property.field) {
          // Replace spaces in the "name" field with hyphens and convert it to lowercase for the object key
          const key = property.name?.toLowerCase().replace(/\s+/g, '-');

          // Assign the "key" field value to the object key and the "value" field to the object value
          mainSpan.style[key] = property.key?.toLowerCase();
          if (key === 'border-color') {
            mainSpan.style['border-style'] = 'solid';
          }

          // display as a hyperlink
          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            mainSpan.style['text-decoration'] = 'underline';
            mainSpan.role = 'button';
          }

          // get the icon and cell value
          switch (property.key) {
            case 'UPLOAD_FILE':
              img.src = `assets/icons/upload/upload.svg`;
              img.alt = property.key?.toLowerCase();
              mainSpan.innerHTML = property.field === 'action' ? '' : mainSpan.innerHTML;
              mainSpan.appendChild(img);
              break;
            case 'ADD_ROW':
              {
                const addIcon = document.createElement('i');

                addIcon.className = 'pi pi-plus-circle p-1 align-middle';
                mainSpan.appendChild(addIcon);
              }
              break;
            case 'EDIT_ROW':
              {
                const editIcon = document.createElement('i');

                editIcon.className += 'pi pi-file-edit p-1 align-middle';
                mainSpan.appendChild(editIcon);
              }
              break;
            default:
              return mainSpan;
          }

          return mainSpan;
        }
      });
    }

    return mainSpan;
  }

  //   getTargetCellData(params: any, mainSpan: any, imgTag: any, isRowSpan: boolean): any {
  //     const span = mainSpan;
  //     const img = imgTag;

  //     img.className += ' icon-class';
  //     img.alt = params.key?.toLowerCase();

  //     const targetValue: any = document.createElement('span');

  //     targetValue.innerHTML = params.value ? params.value : '';
  //     const conditionValue = isRowSpan ? params.data.condition.value : params.data.condition;

  //     switch (conditionValue) {
  //       case '>':
  //         img.src = `assets/icons/greater-than.svg`;
  //         break;
  //       case '>=':
  //         img.src = `assets/icons/greater-than-equals.svg`;
  //         break;
  //       case '<':
  //         img.src = `assets/icons/less-than.svg`;
  //         break;
  //       case '<=':
  //         img.src = `assets/icons/less-than-equals.svg`;
  //         break;
  //       default:
  //         return span;
  //     }

  //     span.appendChild(img);
  //     span.appendChild(targetValue);

  //     return span;
  //   }

  coverageCellData(params: any, mainSpan: any, imgTag: any): any {
    const span = mainSpan;
    const img = imgTag;

    img.className += ' icon-class';
    img.alt = params.key?.toLowerCase();

    const coverageValue: any = document.createElement('span');

    const paramValue = params.value ? params.value : '';

    coverageValue.innerHTML = params.value ? params.value : '';

    switch (paramValue) {
      case 'Current':
        img.src = 'assets/icons/metrics/current.svg';
        break;
      case 'Not Current':
      case 'History':
      case 'Future':
        img.src = 'assets/icons/metrics/not current.svg';
        break;
      case 'N/A':
      case 'Not Available':
        img.src = 'assets/icons/metrics/na.svg';
        break;
      default:
        return span;
    }

    span.appendChild(img);
    span.appendChild(coverageValue);

    return span;
  }

  statusCellData(params: any, mainSpan: any): any {
    const span = mainSpan;

    const paramValue = params.value ? params.value : '';

    // Removing any existing classes before adding a new one
    span.className = '';
    span.style.display = 'inline';

    // Add the appropriate class based on the value
    switch (paramValue) {
      case 'Published':
      case 'Completed':
        span.className = 'success-chip';
        break;
      case 'Revoked':
      case 'Not Published':
      case 'Failed':
        span.className = 'danger-chip';
        break;
      case 'Draft':
      case 'In Progress':
        span.className = 'warning-chip';
        break;
      default:
        return span;
    }

    span.innerHTML = paramValue;

    return span;
  }

  UploadCellRenderer(params: any): any {
    const mainSpan: any = document.createElement('span');

    mainSpan.style.display = 'block';
    mainSpan.style.height = 'inherit';
    mainSpan.innerHTML = params.value || params.value === 0 ? params.value : 'N/A';
    if (params.colDef.headerName === 'Status') {
      mainSpan.innerHTML = '';
      this.statusIconCellData(params, mainSpan);
    }
    if (
      params.colDef.headerName === 'Last Uploaded' ||
      params.colDef.headerName === 'Started At' ||
      params.colDef.headerName === 'Finished At'
    ) {
      mainSpan.innerHTML = params.value ? this.datePipe.transform(params.value, 'yyyy-MM-dd H:mm:ss') : 'N/A';
    }
    if (params.colDef.headerName === 'Send Time') {
      mainSpan.innerHTML = params.value ? this.datePipe.transform(params.value, 'yyyy-MM-dd H:mm:ss') : '';
    }
    if (params.colDef.headerName === 'History') {
      mainSpan.innerHTML = '';
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-link text-info-emphasis clickable-icon cursor-pointer';
      mainSpan.appendChild(addIcon);
    }
    if (params.colDef.headerName === 'Download' || params.colDef.headerName === 'Action') {
      mainSpan.innerHTML = '';
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-download text-info-emphasis clickable-icon cursor-pointer';
      mainSpan.className = 'cursor-pointer';
      mainSpan.appendChild(addIcon);
    }

    if (
      params.colDef.headerName === 'Failed Rows' &&
      (params.data.status === 'PARTIAL_SUCCESS' || params.data.status === 'FAILED')
    ) {
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-download text-info-emphasis clickable-icon cursor-pointer';
      addIcon.style.marginLeft = '5px';
      mainSpan.appendChild(addIcon);
    }

    return mainSpan;
  }

  statusIconCellData(params: any, mainSpan: any): any {
    const addIcon = document.createElement('i');

    switch (params.value) {
      case 'SUBMITTED':
        addIcon.className = 'pi pi-check text-info-emphasis';
        break;
      case 'IN_PROGRESS':
        addIcon.className = 'pi pi-spin pi-hourglass text-info-emphasis';
        break;
      case 'PARTIAL_SUCCESS':
        addIcon.className = 'pi pi-exclamation-circle text-warning-emphasis';
        break;
      case 'SUCCESS':
        addIcon.className = 'pi pi-check text-success';
        break;
      case 'FAILED':
        addIcon.className = 'pi pi-exclamation-triangle text-danger';
        break;
      default:
        break;
    }

    mainSpan.appendChild(addIcon);

    return mainSpan;
  }

  getDistributeCellRenderer(params: any): any {
    const mainSpan: any = document.createElement('span');

    mainSpan.style.display = 'block';
    // mainSpan.style.minWidth = '200px';
    mainSpan.style.height = 'inherit';
    const img: any = document.createElement('img');

    img.className = 'cursor-pointer';

    mainSpan.innerHTML = params.value ? params.value : '';
    if (
      [
        'externalvariable',
        'torecipients',
        'ccrecipients',
        'bccrecipients',
        'testrecipients',
        'docgenerationtime',
        'sendtestemailtime',
        'releaseemailtime',
        'Attributes',
        'actions',
      ].includes(params.colDef.field)
    ) {
      this.getExternalVariable(params, mainSpan);
    }

    return mainSpan;
  }

  // The method to generate content for the AG Grid cell renderer
  getExternalVariable(params: any, mainSpan: HTMLElement): HTMLElement {
    const spanValue = this.renderer.createElement('span'); // Span for value text

    // Clear the mainSpan to ensure no duplicate elements
    // mainSpan.innerHTML = '';
    // Instead of reassigning 'mainSpan', clear it by removing child nodes
    while (mainSpan.firstChild) {
      mainSpan.removeChild(mainSpan.firstChild);
    }

    // Create a wrapper for aligning text and icons without affecting other elements
    const flexContainer = this.renderer.createElement('div'); // Flex container for text and icons

    this.renderer.setStyle(flexContainer, 'display', 'flex');
    this.renderer.setStyle(flexContainer, 'align-items', 'center');

    switch (params.colDef.field) {
      case 'externalvariable': {
        const externalVariableValue = params.data.externalvariableValue || '';

        // spanValue.innerText = externalVariableValue;
        spanValue.innerText = externalVariableValue || '';
        this.renderer.appendChild(flexContainer, spanValue); // Append the value inside flex container

        if (externalVariableValue !== '') {
          const addIcon = this.renderer.createElement('i');

          this.renderer.addClass(addIcon, 'pi');
          this.renderer.addClass(addIcon, 'pi-info-circle'); // PrimeNG info icon
          this.renderer.setStyle(addIcon, 'margin-left', '5px'); // Add some space between value and icon
          this.renderer.appendChild(flexContainer, addIcon); // Append icon inside flex container

          this.renderer.setStyle(addIcon, 'cursor', 'pointer'); // Make the icon clickable
          this.renderer.listen(addIcon, 'click', () => {
            const { id } = params.data;
            const totalVar = externalVariableValue;

            this.triggerExternalVariableDetails(id, totalVar);
          });
        }
        this.renderer.appendChild(mainSpan, flexContainer); // Append the flex container to mainSpan
        break;
      }

      case 'torecipients':
      case 'ccrecipients':
      case 'bccrecipients':
      case 'testrecipients': {
        const recipients = params.data?.[params.colDef.field] || ''; // Default to empty string if null or undefined

        if (!recipients || recipients.trim() === '') {
          spanValue.innerText = '';
          this.renderer.appendChild(mainSpan, spanValue); // Append "N/A"
        } else {
          // Destructure the array to get the first recipient and the rest of the recipients
          const [firstRecipient, ...remainingRecipients] = recipients.includes(',')
            ? recipients.split(',').map((r: any) => r.trim())
            : [recipients.trim()]; // Ensure array, even with a single recipient

          // Append the first recipient
          spanValue.innerText = firstRecipient;
          this.renderer.appendChild(mainSpan, spanValue);

          if (remainingRecipients.length > 0) {
            // Create a badge showing the count of additional recipients
            const tagElement = this.renderer.createElement('span');

            this.renderer.addClass(tagElement, 'p-tag');
            this.renderer.addClass(tagElement, 'p-component');
            this.renderer.addClass(tagElement, 'p-tag-primary'); // Apply 'primary' severity
            this.renderer.setProperty(tagElement, 'innerText', `+${remainingRecipients.length}`);
            this.renderer.setStyle(tagElement, 'margin-left', '5px'); // Add spacing
            this.renderer.appendChild(mainSpan, tagElement); // Append the badge

            // Add click event to trigger the viewRecipientsDetails event
            this.renderer.setStyle(tagElement, 'cursor', 'pointer'); // Make the badge clickable
            this.renderer.listen(tagElement, 'click', () => {
              const type = params.colDef.headerName; // "To Recipients", "Cc Recipients", etc.

              this.triggerRecipientsDetails([firstRecipient, ...remainingRecipients], type);
            });
          }
        }
        break;
      }
      case 'docgenerationtime': {
        // Always get the actual value from params.data.docgenerationtime
        const docGenerationTime = params.data.docgenerationtime || ''; // Use the actual value, don't show 'N/A'
        const docAttributes = params.data.attributes || [];
        const isLoading = params.data.docgenerationtime_isLoading || false; // Check the loading flag

        // Find the color and icon attributes related to 'docgenerationtime'
        const docColorAttribute = docAttributes.find(
          (attr: any) => attr.field === 'docgenerationtime' && attr.name === 'Color'
        );
        const docIconAttribute = docAttributes.find(
          (attr: any) => attr.field === 'docgenerationtime' && attr.name === 'Cell Icon'
        );

        // Display the actual value of docgenerationtime (always)
        let docFormattedDateTime = docGenerationTime;

        // If the docGenerationTime is a valid date (and not in loading state), format it
        if (docGenerationTime) {
          const docDate = new Date(docGenerationTime);

          if (!Number.isNaN(docDate.getTime())) {
            // Only format if it's a valid date
            docFormattedDateTime = `${docDate.getFullYear()}-${String(docDate.getMonth() + 1).padStart(
              2,
              '0'
            )}-${String(docDate.getDate()).padStart(2, '0')} ${String(docDate.getHours()).padStart(2, '0')}:${String(
              docDate.getMinutes()
            ).padStart(2, '0')}:${String(docDate.getSeconds()).padStart(2, '0')}`;
          }
        }

        // Display the actual value, whether it's loading or not
        spanValue.innerText = docFormattedDateTime || ''; // Display 'N/A' if there's no valid value
        this.renderer.appendChild(flexContainer, spanValue); // Append the formatted date/time to the flex container

        // If it's loading, display the hourglass icon next to the value
        if (isLoading) {
          const xhr = new XMLHttpRequest();

          xhr.open('GET', `/assets/actions/loading.svg`, true); // Assuming your hourglass SVG is stored at this path
          xhr.onload = () => {
            if (xhr.status === 200) {
              const svgContent = xhr.responseText;
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
              const svgElement = svgDoc.getElementsByTagName('svg')[0];

              if (svgElement) {
                svgElement.setAttribute('width', '16px');
                svgElement.setAttribute('height', '16px');
                svgElement.setAttribute('style', `margin-left: 5px`);

                this.renderer.appendChild(flexContainer, svgElement); // Append the SVG icon to the flex container
              }
            }
          };
          xhr.send();

          // Set the text color to default black while loading
          this.renderer.setStyle(spanValue, 'color', 'var(--text-color)'); // Ignore any color attributes during loading
          // Update the tooltip to indicate the generation has started
          this.renderer.setAttribute(
            mainSpan,
            'title',
            this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATION_STARTED')
          );
        } else {
          // Handle the color attribute when not in loading state
          let docTextColor = 'var(--text-color)'; // Default text color

          if (docColorAttribute) {
            docTextColor = docColorAttribute.key || 'var(--text-color)'; // Use the color from the attribute
            this.renderer.setStyle(spanValue, 'color', docTextColor); // Apply the color
          }

          // Handle the icon attribute for docgenerationtime
          if (docIconAttribute && docIconAttribute.key) {
            const xhr = new XMLHttpRequest();

            xhr.open('GET', `/assets/actions/${docIconAttribute.key}`, true);
            xhr.onload = () => {
              if (xhr.status === 200) {
                const svgContent = xhr.responseText;
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
                const svgElement = svgDoc.getElementsByTagName('svg')[0];

                if (svgElement) {
                  svgElement.setAttribute('width', '16px');
                  svgElement.setAttribute('height', '16px');
                  svgElement.setAttribute('style', `margin-left: 5px`);

                  const paths = svgElement.querySelectorAll('path, g');
                  const color = '--var(--text-color)';

                  paths.forEach((path: any) => {
                    if (params.data.docgenerationstatus === 'Success' || params.data.docgenerationstatus === 'Failed') {
                      path.setAttribute('fill', docTextColor);
                    } else {
                      path.setAttribute('fill', color);
                    }
                  });

                  // Append the SVG to the flex container
                  this.renderer.appendChild(flexContainer, svgElement);

                  if (params.data.docgenerationstatus === 'Success') {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                    this.renderer.listen(svgElement, 'click', () => {
                      const id = params.data.distributioninstanceid;
                      const docId = params.data.docgenerationtimeValue;

                      this.triggerDownloadDistributeDoc(id, docId); // Trigger the download event
                    });
                  } else if (params.data.docgenerationstatus === 'Failed') {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                    // this.triggerviewFailedDistribute(status, type);
                    this.renderer.listen(svgElement, 'click', () => {
                      const status = params.data.docgenerationerrormsg;
                      const type: any = 1;

                      this.triggerviewFailedDistribute(status, type);
                    });
                  }
                }
              }
            };
            xhr.send();
          }
        }

        // Set the tooltip for the whole cell dynamically based on the generation status
        const updateTooltip = (): void => {
          let tooltipText;

          if (spanValue.innerText === 'N/A') {
            // Do not set tooltip if value is 'N/A'
            this.renderer.removeAttribute(mainSpan, 'title'); // Ensure tooltip is removed

            return; // Exit the function early
          }

          if (isLoading) {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATION_STARTED');
          } else if (params.data.docgenerationstatus === 'Success') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATION_SUCCESS');
          } else if (params.data.docgenerationstatus === 'Failed') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATION_FAILED');
          } else {
            // If the status is neither 'success' nor 'failed', show "Generation started"
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATION_STARTED');
          }
          // Apply the tooltip to the mainSpan (whole cell)
          this.createStyledTooltip(mainSpan, tooltipText);
        };

        // Apply the initial tooltip text
        updateTooltip();

        // Listen to language change events and update the tooltip dynamically
        this.translate.onLangChange.subscribe(() => {
          updateTooltip();
        });

        this.renderer.appendChild(mainSpan, flexContainer); // Append the flex container to mainSpan
        break;
      }

      case 'sendtestemailtime': {
        // Always get the actual value from params.data.sendtestemailtime
        const sendtestemailtime = params.data.sendtestemailtime || ''; // Use the actual value, don't show 'N/A'
        const testReleaseAttributes = params.data.attributes || [];
        const isLoading = params.data.sendtestemailtime_isLoading || false; // Check the loading flag

        // Find the color and icon attributes related to 'sendtestemailtime'
        const testReleaseColorAttribute = testReleaseAttributes.find(
          (attr: any) => attr.field === 'sendtestemailtime' && attr.name === 'Color'
        );
        const testReleaseIconAttribute = testReleaseAttributes.find(
          (attr: any) => attr.field === 'sendtestemailtime' && attr.name === 'Cell Icon'
        );

        // Display the actual value of sendtestemailtime (always)
        let testReleaseFormattedDateTime = sendtestemailtime;

        // If the sendtestemailtime is a valid date (and not in loading state), format it
        if (sendtestemailtime) {
          const testReleaseDate = new Date(sendtestemailtime);

          if (!Number.isNaN(testReleaseDate.getTime())) {
            // Only format if it's a valid date
            testReleaseFormattedDateTime = `${testReleaseDate.getFullYear()}-${String(
              testReleaseDate.getMonth() + 1
            ).padStart(2, '0')}-${String(testReleaseDate.getDate()).padStart(2, '0')} ${String(
              testReleaseDate.getHours()
            ).padStart(2, '0')}:${String(testReleaseDate.getMinutes()).padStart(2, '0')}:${String(
              testReleaseDate.getSeconds()
            ).padStart(2, '0')}`;
          }
        }

        // Display the actual value, whether it's loading or not
        spanValue.innerText = testReleaseFormattedDateTime || ''; // Display 'N/A' if there's no valid value
        this.renderer.appendChild(flexContainer, spanValue); // Append the formatted date/time to the flex container

        // If it's loading, display the hourglass icon next to the value
        if (isLoading) {
          const xhr = new XMLHttpRequest();

          xhr.open('GET', `/assets/actions/loading.svg`, true); // Assuming your hourglass SVG is stored at this path
          xhr.onload = () => {
            if (xhr.status === 200) {
              const svgContent = xhr.responseText;
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
              const svgElement = svgDoc.getElementsByTagName('svg')[0];

              if (svgElement) {
                svgElement.setAttribute('width', '16px');
                svgElement.setAttribute('height', '16px');
                svgElement.setAttribute('style', `margin-left: 5px`);

                this.renderer.appendChild(flexContainer, svgElement); // Append the SVG icon to the flex container
              }
            }
          };
          xhr.send();

          // Set the text color to default black while loading
          this.renderer.setStyle(spanValue, 'color', 'var(--text-color)'); // Ignore any color attributes during loading
          // Update the tooltip to indicate the generation has started
          this.renderer.setAttribute(
            mainSpan,
            'title',
            this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.TEST_RELEASE_STARTED')
          );
        } else {
          // Handle the color attribute when not in loading state
          let docTextColor = 'var(--text-color)'; // Default text color

          if (testReleaseColorAttribute) {
            docTextColor = testReleaseColorAttribute.key || 'var(--text-color)'; // Use the color from the attribute
            this.renderer.setStyle(spanValue, 'color', docTextColor); // Apply the color
          }

          // Handle the icon attribute for sendtestemailtime
          if (testReleaseIconAttribute && testReleaseIconAttribute.key) {
            const xhr = new XMLHttpRequest();

            xhr.open('GET', `/assets/actions/${testReleaseIconAttribute.key}`, true);
            xhr.onload = () => {
              if (xhr.status === 200) {
                const svgContent = xhr.responseText;
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
                const svgElement = svgDoc.getElementsByTagName('svg')[0];

                if (svgElement) {
                  svgElement.setAttribute('width', '16px');
                  svgElement.setAttribute('height', '16px');
                  svgElement.setAttribute('style', `margin-left: 5px`);

                  const paths = svgElement.querySelectorAll('path, g');
                  const color = '--var(--text-color)';

                  paths.forEach((path: any) => {
                    if (params.data.sendtestemailstatus === 'Success' || params.data.sendtestemailstatus === 'Failed') {
                      path.setAttribute('fill', docTextColor);
                    } else {
                      path.setAttribute('fill', color);
                    }
                  });

                  // Append the SVG to the flex container
                  this.renderer.appendChild(flexContainer, svgElement);

                  if (params.data.sendtestemailstatus === 'Failed') {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                    this.renderer.listen(svgElement, 'click', () => {
                      const status = params.data.sendtesterrormsg;
                      const type: any = 3;

                      this.triggerviewFailedDistribute(status, type);
                    });
                  } else {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                  }
                }
              }
            };
            xhr.send();
          }
        }

        // Set the tooltip for the whole cell dynamically based on the generation status
        const updateTooltip = (): void => {
          let tooltipText;

          if (spanValue.innerText === 'N/A') {
            // Do not set tooltip if value is 'N/A'
            this.renderer.removeAttribute(mainSpan, 'title'); // Ensure tooltip is removed

            return; // Exit the function early
          }

          if (isLoading) {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.TEST_RELEASE_STARTED');
          } else if (params.data.sendtestemailstatus === 'Success') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.TEST_RELEASE_SUCCESS');
          } else if (params.data.sendtestemailstatus === 'Failed') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.TEST_RELEASE_FAILED');
          } else {
            // If the status is neither 'success' nor 'failed', show "Generation started"
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.TEST_RELEASE_STARTED');
          }
          // Apply the tooltip to the mainSpan (whole cell)
          this.createStyledTooltip(mainSpan, tooltipText);
        };

        // Apply the initial tooltip text
        updateTooltip();

        // Listen to language change events and update the tooltip dynamically
        this.translate.onLangChange.subscribe(() => {
          updateTooltip();
        });

        this.renderer.appendChild(mainSpan, flexContainer); // Append the flex container to mainSpan
        break;
      }
      case 'releaseemailtime': {
        // Always get the actual value from params.data.releaseemailtime
        const releaseemailtime = params.data.releaseemailtime || ''; // Use the actual value, don't show 'N/A'
        const testReleaseAttributes = params.data.attributes || [];
        const isLoading = params.data.releaseemailtime_isLoading || false; // Check the loading flag

        // Find the color and icon attributes related to 'releaseemailtime'
        const testReleaseColorAttribute = testReleaseAttributes.find(
          (attr: any) => attr.field === 'releaseemailtime' && attr.name === 'Color'
        );
        const testReleaseIconAttribute = testReleaseAttributes.find(
          (attr: any) => attr.field === 'releaseemailtime' && attr.name === 'Cell Icon'
        );

        // Display the actual value of releaseemailtime (always)
        let testReleaseFormattedDateTime = releaseemailtime;

        // If the releaseemailtime is a valid date (and not in loading state), format it
        if (releaseemailtime) {
          const testReleaseDate = new Date(releaseemailtime);

          if (!Number.isNaN(testReleaseDate.getTime())) {
            // Only format if it's a valid date
            testReleaseFormattedDateTime = `${testReleaseDate.getFullYear()}-${String(
              testReleaseDate.getMonth() + 1
            ).padStart(2, '0')}-${String(testReleaseDate.getDate()).padStart(2, '0')} ${String(
              testReleaseDate.getHours()
            ).padStart(2, '0')}:${String(testReleaseDate.getMinutes()).padStart(2, '0')}:${String(
              testReleaseDate.getSeconds()
            ).padStart(2, '0')}`;
          }
        }

        // Display the actual value, whether it's loading or not
        spanValue.innerText = testReleaseFormattedDateTime || ''; // Display 'N/A' if there's no valid value
        this.renderer.appendChild(flexContainer, spanValue); // Append the formatted date/time to the flex container

        // If it's loading, display the hourglass icon next to the value
        if (isLoading) {
          const xhr = new XMLHttpRequest();

          xhr.open('GET', `/assets/actions/loading.svg`, true); // Assuming your hourglass SVG is stored at this path
          xhr.onload = () => {
            if (xhr.status === 200) {
              const svgContent = xhr.responseText;
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
              const svgElement = svgDoc.getElementsByTagName('svg')[0];

              if (svgElement) {
                svgElement.setAttribute('width', '16px');
                svgElement.setAttribute('height', '16px');
                svgElement.setAttribute('style', `margin-left: 5px`);

                this.renderer.appendChild(flexContainer, svgElement); // Append the SVG icon to the flex container
              }
            }
          };
          xhr.send();

          // Set the text color to default black while loading
          this.renderer.setStyle(spanValue, 'color', 'var(--text-color)'); // Ignore any color attributes during loading
          // Update the tooltip to indicate the generation has started
          this.renderer.setAttribute(
            mainSpan,
            'title',
            this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE_STARTED')
          );
        } else {
          // Handle the color attribute when not in loading state
          let docTextColor = 'var(--text-color)'; // Default text color

          if (testReleaseColorAttribute) {
            docTextColor = testReleaseColorAttribute.key || 'var(--text-color)'; // Use the color from the attribute
            this.renderer.setStyle(spanValue, 'color', docTextColor); // Apply the color
          }

          // Handle the icon attribute for releaseemailtime
          if (testReleaseIconAttribute && testReleaseIconAttribute.key) {
            const xhr = new XMLHttpRequest();

            xhr.open('GET', `/assets/actions/${testReleaseIconAttribute.key}`, true);
            xhr.onload = () => {
              if (xhr.status === 200) {
                const svgContent = xhr.responseText;
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
                const svgElement = svgDoc.getElementsByTagName('svg')[0];

                if (svgElement) {
                  svgElement.setAttribute('width', '16px');
                  svgElement.setAttribute('height', '16px');
                  svgElement.setAttribute('style', `margin-left: 5px`);

                  const paths = svgElement.querySelectorAll('path, g');
                  const color = '--var(--text-color)';

                  paths.forEach((path: any) => {
                    if (params.data.releaseemailstatus === 'Success' || params.data.releaseemailstatus === 'Failed') {
                      path.setAttribute('fill', docTextColor);
                    } else {
                      path.setAttribute('fill', color);
                    }
                  });

                  // Append the SVG to the flex container
                  this.renderer.appendChild(flexContainer, svgElement);
                  if (params.data.releaseemailstatus === 'Failed') {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                    this.renderer.listen(svgElement, 'click', () => {
                      const status = params.data.releaseerrormsg;
                      const type: any = 2;

                      this.triggerviewFailedDistribute(status, type);
                    });
                  } else {
                    this.renderer.setStyle(svgElement, 'cursor', 'pointer');
                  }
                }
              }
            };
            xhr.send();
          }
        }

        // Set the tooltip for the whole cell dynamically based on the generation status
        const updateTooltip = (): void => {
          let tooltipText;

          if (spanValue.innerText === 'N/A') {
            // Do not set tooltip if value is 'N/A'
            this.renderer.removeAttribute(mainSpan, 'title'); // Ensure tooltip is removed

            return; // Exit the function early
          }

          if (isLoading) {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE_STARTED');
          } else if (params.data.releaseemailstatus === 'Success') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE_SUCCESS');
          } else if (params.data.releaseemailstatus === 'Failed') {
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE_FAILED');
          } else {
            // If the status is neither 'success' nor 'failed', show "Generation started"
            tooltipText = this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE_STARTED');
          }
          // Apply the tooltip to the mainSpan (whole cell)
          this.createStyledTooltip(mainSpan, tooltipText);
        };

        // Apply the initial tooltip text
        updateTooltip();

        // Listen to language change events and update the tooltip dynamically
        this.translate.onLangChange.subscribe(() => {
          updateTooltip();
        });

        this.renderer.appendChild(mainSpan, flexContainer); // Append the flex container to mainSpan
        break;
      }
      case 'actions': {
        // Define the desired order of the icons
        const iconOrder = ['generate.svg', 'release.svg', 'testrelease.svg'];

        // Define the mapping of icon keys to file paths in your assets
        const iconMap: { [key: string]: string } = {
          'generate.svg': 'assets/actions/generate.svg',
          'release.svg': 'assets/actions/release.svg',
          'testrelease.svg': 'assets/actions/testrelease.svg',
        };

        // Use an existing flexContainer or create one if it doesn't exist
        // eslint-disable-next-line @typescript-eslint/no-shadow
        let flexContainer = mainSpan.querySelector('.actions-flex-container') as HTMLElement;

        if (!flexContainer) {
          // Create a flex container for the icons
          flexContainer = document.createElement('div') as HTMLElement;
          flexContainer.classList.add('actions-flex-container'); // Add a class to identify the flex container
          flexContainer.style.display = 'flex';
          flexContainer.style.alignItems = 'center';
        }

        // Clear existing icons
        while (flexContainer.firstChild) {
          flexContainer.removeChild(flexContainer.firstChild);
        }

        // Get and sort actions based on the predefined order
        const actionsAttributes = params.data.attributes
          .filter((attr: any) => attr.field === 'actions')
          .sort((a: any, b: any) => {
            const indexA = iconOrder.indexOf(a.key);
            const indexB = iconOrder.indexOf(b.key);

            return indexA - indexB; // Sort based on the predefined order
          });

        // Check if there are actions to render
        if (actionsAttributes.length > 0) {
          actionsAttributes.forEach((attr: any) => {
            const iconSrc = iconMap[attr.key]; // Get the mapped icon path

            if (iconSrc) {
              const imgElement = document.createElement('img');

              imgElement.src = iconSrc; // Set the image source
              imgElement.style.marginLeft = '5px'; // Add spacing between icons
              imgElement.alt = attr.key; // Set alt text for accessibility

              // Create a tooltip for each image
              const tooltipText = this.getTooltipText(attr.key);

              this.createStyledTooltip(imgElement, tooltipText); // Create tooltip for each icon

              // Handle click actions and disabling based on conditions
              const isDisabled = this.getActionDisabledCondition(attr.key, params.data);

              if (isDisabled) {
                imgElement.style.opacity = '0.5'; // Indicate disabled state
              } else {
                imgElement.style.cursor = 'pointer'; // Enable the icon
                imgElement.addEventListener('click', () => {
                  this.handleActionClick(attr.key, attr.value, params);
                });
              }

              // Append the image element to the flex container
              flexContainer.appendChild(imgElement);
            }
          });

          // Finally, append the flex container to the mainSpan
          if (!mainSpan.contains(flexContainer)) {
            mainSpan.appendChild(flexContainer); // Append flex container if not already appended
          }
        } else {
          // If no actions are available
          const noActionsText = document.createElement('span');

          noActionsText.innerText = 'No actions available';
          flexContainer.appendChild(noActionsText);

          if (!mainSpan.contains(flexContainer)) {
            mainSpan.appendChild(flexContainer); // Append flex container if not already appended
          }
        }

        break;
      }

      default: {
        spanValue.innerText = 'No data available';
        this.renderer.appendChild(mainSpan, spanValue);
        break;
      }
    }

    return mainSpan;
  }

  // Function to trigger event from the cell renderer
  triggerExternalVariableDetails(id: number, totalVar: number): void {
    this.externalVariableClicked.emit({ id, totalVar }); // Emit the event with the id and totalVar
  }

  // Function to trigger the recipients details event
  triggerRecipientsDetails(recipients: string[], type: string): void {
    this.recipientsDetailsClicked.emit({ recipients, type }); // Emit the event
  }

  triggerDownloadDistributeDoc(id: number, docId: string): void {
    this.downloadDistributeDocClicked.emit({ id, docId }); // Emit the event for download
  }

  triggerviewFailedDistribute(status: string[], type: string): void {
    this.viewFailedDistributeclicked.emit({ status, type }); // Emit the event
  }

  handleActionClick(key: string, value: string, params: any): void {
    switch (key) {
      case 'generate.svg':
        // eslint-disable-next-line no-param-reassign
        params.data.docgenerationtime_isLoading = true;
        params.api.refreshCells({ rowNodes: [params.node], force: true });
        this.triggerGenerateAction(params.data.id, params);
        break;

      case 'release.svg':
        // eslint-disable-next-line no-param-reassign
        params.data.releaseemailtime_isLoading = true;
        params.api.refreshCells({ rowNodes: [params.node], force: true });
        this.triggerReleaseAction(params.data.id, params);
        break;

      case 'testrelease.svg':
        // eslint-disable-next-line no-param-reassign
        params.data.sendtestemailtime_isLoading = true;
        params.api.refreshCells({ rowNodes: [params.node], force: true });
        this.triggerTestReleaseAction(params.data.id, params);
        break;

      default:
        break;
    }
  }

  triggerGenerateAction(id: number, params: any): any {
    this.triggerGenerateActionClicked.emit({ id, params });
  }

  triggerReleaseAction(id: number, params: any): any {
    this.triggerReleaseActionClicked.emit({ id, params });
  }

  triggerTestReleaseAction(id: number, params: any): any {
    this.triggerTestReleaseActionClicked.emit({ id, params });
  }

  getActionDisabledConditionold(actionKey: string, rowData: any): boolean {
    const isStatusStarted = (status: string | null | undefined): boolean => status === 'Started';

    // Common condition to disable actions if docgenerationtime is loading
    if (rowData.docgenerationtime_isLoading) {
      return true;
    }

    // Helper function to check if any key statuses are 'Started'
    const isAnyStatusStarted = (...statuses: (string | null | undefined)[]): boolean => {
      return statuses.some(isStatusStarted);
    };

    // Generate action logic
    const isGenerateActionDisabled = (): boolean => {
      return isAnyStatusStarted(rowData.docgenerationstatus, rowData.sendtestemailstatus, rowData.releaseemailstatus);
    };

    // Release and test release action logic
    const isReleaseActionDisabled = (): boolean => {
      // Disable if docGenerationStatus is not 'Success'
      if (rowData.docgenerationstatus !== 'Success') {
        return true;
      }

      // Disable if either sendTestEmailStatus or releaseEmailStatus is 'Started'
      return isAnyStatusStarted(rowData.sendtestemailstatus, rowData.releaseemailstatus);
    };

    switch (actionKey) {
      case 'generate.svg':
        return isGenerateActionDisabled();

      case 'release.svg':
      case 'testrelease.svg':
        return isReleaseActionDisabled();

      default:
        return false; // Default case, assuming no conditions for other actions
    }
  }

  getActionDisabledCondition(actionKey: string, rowData: any): boolean {
    const isStatusStarted = (status: string | null | undefined): boolean => status === 'Started';

    // Common condition to disable actions if any of the time loaders are active
    if (
      rowData.docgenerationtime_isLoading ||
      rowData.sendtestemailtime_isLoading ||
      rowData.releaseemailtime_isLoading
    ) {
      return true;
    }

    const isAnyStatusStarted = (...statuses: (string | null | undefined)[]): boolean => {
      return statuses.some(isStatusStarted);
    };

    const isGenerateActionDisabled = (): boolean => {
      return isAnyStatusStarted(rowData.docgenerationstatus, rowData.sendtestemailstatus, rowData.releaseemailstatus);
    };

    const isReleaseActionDisabled = (): boolean => {
      if (rowData.docgenerationstatus !== 'Success') {
        return true;
      }

      return isAnyStatusStarted(rowData.sendtestemailstatus, rowData.releaseemailstatus);
    };

    switch (actionKey) {
      case 'generate.svg':
        return isGenerateActionDisabled();

      case 'release.svg':
      case 'testrelease.svg':
        return isReleaseActionDisabled();

      default:
        return false; // Default case, assuming no conditions for other actions
    }
  }

  createStyledTooltip(button: Element, tooltipText: string): any {
    const tooltip = this.renderer.createElement('div');

    this.renderer.setProperty(tooltip, 'innerText', tooltipText);
    this.renderer.setStyle(tooltip, 'position', 'absolute');
    this.renderer.setStyle(tooltip, 'background-color', '#6c6c6c');
    this.renderer.setStyle(tooltip, 'color', '#fff');
    this.renderer.setStyle(tooltip, 'padding', '5px');
    this.renderer.setStyle(tooltip, 'border-radius', '4px');
    this.renderer.setStyle(tooltip, 'font-size', '12px');
    this.renderer.setStyle(tooltip, 'white-space', 'nowrap');
    this.renderer.setStyle(tooltip, 'display', 'none'); // Initially hidden
    this.renderer.setStyle(tooltip, 'z-index', '1000'); // Ensure tooltip is on top
    this.renderer.addClass(tooltip, 'custom-tooltip'); // Add a class for easy selection

    this.renderer.appendChild(document.body, tooltip);

    // Function to position the tooltip
    const updateTooltipPosition = (): any => {
      const buttonRect = button.getBoundingClientRect(); // Get the button's position
      const tooltipRect = tooltip.getBoundingClientRect(); // Get the tooltip's dimensions

      // Position tooltip above the button
      const top = buttonRect.top - tooltipRect.height - 5; // Adjust top margin
      const left = buttonRect.left + buttonRect.width / 2 - tooltipRect.width / 2; // Centered horizontally with left margin

      this.renderer.setStyle(tooltip, 'top', `${top}px`);
      this.renderer.setStyle(tooltip, 'left', `${left}px`);
    };

    const showTooltip = (): any => {
      this.renderer.setStyle(tooltip, 'display', 'block');
      updateTooltipPosition();
    };

    const hideTooltip = (): any => {
      this.renderer.setStyle(tooltip, 'display', 'none');
    };

    this.renderer.listen(button, 'mouseover', showTooltip);
    this.renderer.listen(button, 'mousemove', updateTooltipPosition);
    this.renderer.listen(button, 'mouseout', hideTooltip);

    // Ensure tooltip hides when mouse moves away from the tooltip itself
    this.renderer.listen(document, 'mousemove', (event: MouseEvent) => {
      if (!tooltip.contains(event.target as Node) && !button.contains(event.target as Node)) {
        hideTooltip();
      }
    });

    return tooltip; // Return the created tooltip element
  }

  getTooltipText(key: string): string {
    switch (key) {
      case 'generate.svg':
        return this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.GENERATE');
      case 'release.svg':
        return this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.RELEASE');
      case 'testrelease.svg':
        return this.translate.instant('MODULES.DISTRIBUTIONS.DISTRIBUTE.SEND_TEST_EMAIL');
      default:
        return '';
    }
  }
}
